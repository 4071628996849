import React from 'react';
import { Layout } from '../components/Layout';
import { graphql, PageProps } from 'gatsby';
import SEO from '../components/SEO';
import { SanityPerson } from '../../graphql-types';
import BlockContent from '../components/BlockContent';
import {
  Box,
  H2,
  H3,
  ThemeProvider,
  Pxs,
  Text,
  UnderlinedText,
  SolidDarkTheme,
  getResponsiveProp,
  HR
} from '@parsleyhealth/cilantro-ui';
import PortableTextToPlainText from '../lib/portabletext-to-plain-text';
import SanityImage from '../components/NewSanityImage';

interface PageData {
  page: Required<
    Pick<
      SanityPerson,
      'authorByLine' | '_rawBackground' | 'profileImage' | 'role' | 'roleTitle'
    >
  >;
}

export const query = graphql`
  query PersonProfilePageTemplateQuery($id: String) {
    page: sanityPerson(id: { eq: $id }) {
      authorByLine
      _rawBackground
      _rawInterests
      _rawBiography
      profileImage {
        _type
        ...SanityImageFragment
      }
      role
      roleTitle
    }
  }
`;

export const PersonProfilePageTemplate: React.FC<PageProps<PageData>> = ({
  data
}) => {
  const {
    authorByLine,
    _rawBackground,
    profileImage,
    role,
    roleTitle
  } = data.page;
  const PlainTextBackground = _rawBackground
    ? PortableTextToPlainText(_rawBackground)
    : null;

  return (
    <ThemeProvider>
      <Layout themeNav={SolidDarkTheme}>
        <SEO
          pageTitle={authorByLine}
          pageDescription={PlainTextBackground || roleTitle}
        />
        <Box maxWidth={1080} my="xxl" mx="auto" pt="l" px="s">
          {/* display: none - temporarily because the team page is not yet complete  */}
          <Box display="none" mb="s">
            <a href="/team">
              <UnderlinedText variant="pExtraSmall">
                All Team Members
              </UnderlinedText>
            </a>
            <Pxs mx="xs">{'>'}</Pxs>
            <Pxs>{authorByLine}</Pxs>
          </Box>
          <Box
            mb="m"
            display="flex"
            flexDirection={getResponsiveProp('column', 'row')}
          >
            {profileImage?.asset?.fluid && (
              <Box
                width={getResponsiveProp('100%', '40%')}
                mb={getResponsiveProp('s', 'none')}
              >
                <SanityImage
                  image={{ ...profileImage }}
                  imageSizes={getResponsiveProp('100vw', '50vw')}
                />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="column"
              width={getResponsiveProp('100%', '60%')}
              pl={getResponsiveProp('none', 's')}
            >
              <H2>{authorByLine}</H2>
              <H3>{roleTitle ? roleTitle : role}</H3>
              <Text variant="pRegular">
                {_rawBackground && <BlockContent blocks={_rawBackground} />}
              </Text>
            </Box>
          </Box>
          <HR bg="grayBackground" />
        </Box>
      </Layout>
    </ThemeProvider>
  );
};

export default PersonProfilePageTemplate;
